
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { PositionBaseResponse } from "@/core/interfaces/ApiResponses";
import ChucVuService from "@/core/services/ChucVu.service";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {swalNotification} from '@/core/helpers/utils';

export default defineComponent({
  name: "position-detail",
  setup() {
    const route = useRoute();

    const positionDetail = ref<PositionBaseResponse | null>(null);

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý chức vụ");
      try {
        const {
          data: { data },
        } = await ChucVuService.get(+route.params.id);
        if (data) {
          positionDetail.value = { ...data };
        }
      } catch {
				await swalNotification(
					'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin chức vụ, mời bạn quay lại sau',
					'error'
				);
      }
    });

    return {
      positionDetail,
      updateLinkComputed: computed(
        () => `/quan-ly/chuc-vu/cap-nhat/${positionDetail?.value?.id}`
      ),
    };
  },
});
